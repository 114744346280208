import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import css from './AboutPage.module.css';

export const SectionAbout = props => {
  const { sectionId, marketplaceName } = props;
  return (
    <div id={sectionId}>
      <div className={css.sectionAbout}>
        <Heading className={css.aboutTitle} as="h1">
          <FormattedMessage
            id="AboutPage.SectionAbout.title"
            values={{ marketplaceName: marketplaceName }}
          />
        </Heading>
        <p className={css.aboutText}>
          <FormattedMessage id="AboutPage.SectionAbout.text" />
        </p>
        <p className={css.aboutSmallText}>
          <FormattedMessage id="AboutPage.SectionAbout.smallText" />
        </p>
      </div>
      {/* <div className={classNames(css.videoResponsive, css.videoPosition)}>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/492J8i7R0do?si=0QcE8E67o99g_hrO`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube"
        />
      </div> */}
    </div>
  );
};

export default SectionAbout;
