import { LISTING_TYPE_TALENT, LISTING_TYPE_COMPANY } from '../../util/types';

/**
 * Load profile data from localStorage or provided data.
 *
 * This function checks if the environment is client-side (in the browser).
 * If it is, it attempts to retrieve profile data for talent and company from localStorage.
 * If profile data is provided as arguments, it uses that data instead of retrieving from localStorage.
 *
 * @param {string} talentProfileData - Optional. Profile data for talent.
 * @param {string} companyProfileData - Optional. Profile data for company.
 * @return {Object} An object containing the parsed profile data for talent and company,
 *                   or an object with null values if not found or if not running in a browser environment.
 */
export const loadProfileData = (talentProfileData, companyProfileData) => {
  if (typeof window !== 'undefined') {
    talentProfileData =
      (talentProfileData ? JSON.stringify(talentProfileData) : null) ||
      localStorage.getItem(`${LISTING_TYPE_TALENT}ProfileData`);
    companyProfileData =
      (companyProfileData ? JSON.stringify(companyProfileData) : null) ||
      localStorage.getItem(`${LISTING_TYPE_COMPANY}ProfileData`);

    return {
      talent: talentProfileData ? JSON.parse(talentProfileData) : null,
      company: companyProfileData ? JSON.parse(companyProfileData) : null,
    };
  }

  return {
    talent: null,
    company: null,
  };
};
